<template>
  <div class="about">
    <Header 
      title="Léo Blondin" 
      work="Old PF" 
      about="About" 
    />
    <CanvasParticle />
    <AboutContent />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import CanvasParticle from '@/components/CanvasParticle.vue'
import AboutContent from '@/components/AboutContent.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'About',
  components: {
    Header,
    CanvasParticle,
    AboutContent,
    Footer
  }
}
</script>

<style lang="scss">
  .about {
    position: relative;
    overflow-x: hidden;
  }
</style>