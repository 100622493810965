<template>
  <div class="aboutContent">
    <div class="aboutContent_informations">
      <div class="aboutContent_picture" >
        <div class="hidding_image" ref="image"></div>
        <img src="../assets/images/interface/Leo_Blondin.jpg" alt="Léo Blondin portrait">
      </div>
      <div class="aboutContent_content">
        <div class="aboutContent_content_title">
          <div class="hidding">
            <div class="bigTitle first showFirst" ref="name">Oh hi Mark</div>
          </div>
          <div class="hidding">
              <div class="bigTitle sec showFirst" ref="desc">do we know</div>
          </div>
          <div class="hidding">
              <div class="bigTitle thrd showFirst" ref="job">each other ?</div>
          </div>
          <div class="circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="648.443" height="302.236" viewBox="0 0 648.443 302.236" ref="circle1">
              <g id="Ellipse_4" data-name="Ellipse 4" transform="matrix(0.995, -0.105, 0.105, 0.995, 0, 65.539)" fill="none" stroke="#fff" stroke-width="1" opacity="0.7">
                <ellipse cx="313.5" cy="119" rx="313.5" ry="119" stroke="none"/>
                <ellipse cx="313.5" cy="119" rx="313" ry="118.5" fill="none"/>
              </g>
            </svg>
          </div>
        </div>
        <div class="aboutContent_content_description" ref="description">
          <div class="infos">
            I'm Léo, I'm interested in animation and interaction on web.<br> I loooove when things move and make weird noises.
          </div>
          <div class="infos">
            As you can see on my pic, I really enjoy colors<br> (where are those on my website ???)
          </div>
          <div class="infos">
            I'm currently improving my skills in <br> creative development at Gobelins in Paris
          </div>
        </div>
        <div class="aboutContent_content_contact">
          <div class="hidding">
            <div class="bigTitle first showSecond" ref="name">Contact me</div>
          </div>
          <div class="hidding">
              <div class="bigTitle sec showSecond" ref="desc">for hot stuff</div>
          </div>
          <div class="circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="651.121" height="205.416" viewBox="0 0 651.121 205.416" ref="circle2">
              <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(0 33.652) rotate(-3)" fill="none" stroke="#fff" stroke-width="1" opacity="0.7">
                <ellipse cx="321.5" cy="86" rx="321.5" ry="86" stroke="none"/>
                <ellipse cx="321.5" cy="86" rx="321" ry="85.5" fill="none"/>
              </g>
            </svg>

          </div>
        </div>
        <div class="aboutContent_content_link" ref="contact">
          <p class="mail_link">Look how easy it is, click on the email : <span><a href="mailto:hello@leoblondin.com">hello@leoblondin.com</a></span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
  import { Power4, gsap } from 'gsap'
  import SplitText from '../script/Split'

  export default {
    name: 'AboutContent',

    mounted() {
          //Declaration for GSAP
            const { description, image, circle1, circle2, contact } = this.$refs
            const timeline = new gsap.timeline()
  
            const showFirst = new SplitText('.showFirst', { type: 'chars, words' })
            const showSecond = new SplitText('.showSecond', { type: 'chars, words' })
            const char_showFirst = showFirst.chars
            const char_showSecond = showSecond.chars
  
            // gsap.registerPlugin(SplitText)
  
            console.log(showFirst);
              // Anime for GSAP
              timeline.fromTo(image, {bottom:"-120px"}, { duration: 2.5, bottom:'105%', rotation:0, display:'none', ease: Power4.easeOut},'=0.5')
              timeline.fromTo(char_showFirst, { duration: 0.5, opacity:0, y:"150%"}, { duration: 1.5, opacity:1,  y:0, stagger: 0.07, ease: Power4.easeOut},'-=1.25')
              timeline.to(circle1, 3, {strokeDashoffset: 0, ease: Power4.easeOut}, "-=2.5")
              timeline.fromTo(description, {duration: 0.1, opacity:0}, { duration: 0.7, opacity:1, ease: Power4.easeOut}, '-=1.5')
              timeline.fromTo(char_showSecond, { duration: 0.5, opacity:0, y:"150%"}, { duration: 1.5, opacity:1,  y:0, stagger: 0.07, ease: Power4.easeOut},'-=1.5')
              timeline.to(circle2, 8, {strokeDashoffset: 0, ease: Power4.easeOut}, "-=1.8")
              timeline.fromTo(contact, {duration: 0.1, opacity:0}, { duration: 0.7, opacity:1, ease: Power4.easeOut}, '-=7')

         

    }
  }

</script>

<style lang="scss">
  .aboutContent {
    position: relative;

    &_informations {

      position: relative;
      z-index: 300;
      width: 90%;
      margin: auto;
      padding-top: 50px;
    }

    &_picture {

      position: sticky;
      top: 150px;
      width: 50%;
      // transform: translateY(-50%);
      display: inline-block;
      vertical-align: top;
      transform-origin: center bottom;
      overflow: hidden;

      .hidding_image {
        position: absolute;
        top: -80px;
        left: 0;
        right: -150px;
        bottom: 0;
        transform-origin: center top;
        background-color: #000;
        z-index: 500;
        transform: rotate(25deg);
      }

      img {
        width: 75%;
      }

    }

    &_content {

      // top: 100px;
      // left: 50%;
      display: inline-block;
      vertical-align: top;

      &_title {

        position: relative;
        margin-bottom: 120px;
        width: 650px ;
        padding-top: 80px;

        .hidding {
          overflow: hidden;
          padding-top: 10px;

          .bigTitle {
            width: 100%;
            color: #fff;
            font-family: "Blake";
            font-size: 3.625rem;
            text-align: left;
            line-height: 0.8;
            margin: 5px 0;
          }

          .sec {
            text-align: right;
          }

          .thrd {
            text-align: center;
          }
        }

        .circle {
          position: absolute;
          top: 30px;

          svg {
            stroke-dasharray: 2000px;
            stroke-dashoffset: 2000;
          }
        }

      }

      &_description {

        .infos {
          width: 100%;
          color: #fff;
          font-family: "Telegraf-UltraLight";
          font-size: 1.5rem;
          text-align: left;
          text-align: center;
          margin: 25px 0 ;
        }
      }

      &_contact {

        position: relative;
        margin-top: 120px;
        width: 650px ;

        .hidding {
          overflow: hidden;
          padding-top: 10px;

          .bigTitle {
            width: 100%;
            color: #fff;
            font-family: "Blake";
            font-size: 3.625rem;
            text-align: left;
            line-height: 0.8;
            margin: 5px 0;
          }

          .sec {
            text-align: right;
          }
        }

        .circle {
          position: absolute;
          top: -50px;

          svg {
            stroke-dasharray: 3500px;
            stroke-dashoffset: 3500;
          }
        }

      }

      &_link {
        margin-top: 120px;
        .mail_link {
          width: 100%;
          color: #fff;
          font-family: "Telegraf-UltraLight";
          font-size: 1.5rem;
          text-align: left;
          text-align: center;
          margin: 50px 0 ;

          span {
            display: block;

            a {
              color: #fff;
              font-family: "Telegraf-UltraLight";
            }
          }
        }
      }

    }

  }

  @media (max-width: 1500px) {
    .aboutContent_picture {
      width: 45% !important;
    }
  }

  @media (max-width: 1281px) {
    .aboutContent_picture {
      width: 40% !important;
    }
    .canvas {
      display: none !important;
    }
  }

  @media (max-width: 1025px) {
    .aboutContent_informations {
      display: flex !important;
      flex-flow: column nowrap ;
      align-items: center;
    }

    .aboutContent_picture {
      width: 70% !important;
      margin: 60px !important;
      position: static !important;
    }
  }

  @media (max-width: 420px) {

    .aboutContent {
      .aboutContent_picture {
      width: 100% !important;
      margin: 60px !important;
      position: static !important;
      display: block !important;
      margin: 30px auto !important;

      img {
        width: 100% !important;
      }
    }

    .hidding_image {
      right: -250px !important;
    }

    .aboutContent_content {
      width: 100%;

      &_title {
        width: 100% !important;
      }

      &_contact {
        width: 100% !important;
      }
    }
      .bigTitle {
      font-size: 1.625em !important;
    }

    .mail_link,
    .infos {
      font-size: 1em !important;
    }
    }
    
  }

</style>